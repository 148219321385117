import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import GlobalUSP from '../components/global-usp'
import Figure from '../components/figure'
import Reviews from '../components/reviews'
import ArrowRightIcon from '../images/arrow-right.svg'
import BlockContent from '../components/block-content'
import BlogPostPreview from '../components/blog-post-preview'
import { mapEdgesToNodes } from '../utils/helpers'
import HyphenatedTitle from '../components/hyphenatedTitle'
import InternalLink from '../components/internalLink'
import InternalOrExternalLink from '../components/internalOrExternalLink'
import KlaviyoForm from '../components/klaviyo-signup-form'

const IndexPage = props => {
  const { data, errors } = props
  const page = data && data.page
  const pageDescriptionBlock = data && data.site && data.site.pageDescriptionBlock
  const blog = page && page.articles
  const categories = data && data.categories && mapEdgesToNodes(data.categories)

  const LinkWrapper = ({ item, children, ...props }) => {
    return (
      <>
        {item.internalLink && (
          <InternalLink reference={item.internalLink.reference} {...props}>{children}</InternalLink>
        )}

        {!item.internalLink && (
          <InternalOrExternalLink to={item.url} {...props}>{children}</InternalOrExternalLink>
        )}
      </>
    )
  }

  return (
    <>
      <SEO page={page} />

      <div className='header-offset bg-primary-background-sand'>
        <div className='pt-10 lg:pt-20 pb-20 md:pb-40 flex flex-col xl:flex-row xl:items-center'>
          <div className='mx-5 lg:mx-10 xl:mx-20 md:mr-8 mb-6 xl:mb-0 xl:w-1/3'>
            <h1 className='h2 font-bold max-w-sm'>
              <HyphenatedTitle text={page.title} />
            </h1>
            <BlockContent className='lead' blocks={page._rawShortDescription} />
          </div>

          <div className='mx-5 xl:w-2/3'>
            <div className='-mx-2 lg:mx-0 flex flex-wrap lg:flex-no-wrap'>
              {page.featuredServices.map((service, i) => (
                <LinkWrapper
                  item={service}
                  key={i}
                  className='inline-block relative px-2 w-1/2 mb-4'
                >
                  <Figure aspectRatio={.85} node={service.image} className='rounded-md overflow-hidden' />

                  <div className='absolute bottom-0 w-full p-3 pr-8 py-3 md:p-4 md:pr-8 flex items-center'>
                    {service.text}

                    <img src={ArrowRightIcon} alt='' className='w-6 ml-auto pl-2' />
                  </div>
                </LinkWrapper>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='md:flex bg-primary'>
        {!!page.image && (
          <Figure node={page.image} className='md:w-1/2 flex-shrink-0' />
        )}

        {!!page._rawSlogan && (
          <BlockContent className='display-h1 mb-0 md:text-screen-1 flex content-center items-center p-8 py-16 lg:p-20 xl:px-32' blocks={page._rawSlogan} />
        )}
      </div>

      {page.howItWorksText && (
        <div className='overflow-hidden relative'>
          <div className='radial-gradient top right'></div>

          <section className='py-20 lg:py-32 px-5 lg:px-8 lg:px-12 max-w-lg lg:max-w-screen-xl lg:mx-auto'>
            <div className='mb-12'>
              <h2 className='display-h2'>Slik fungerer det</h2>
            </div>

            <div className='lg:flex max-w-lg lg:max-w-screen-xl lg:mx-auto lg:-mx-12'>
              {page.howItWorksText.map((row, i) => (
                <div key={i} className='mb-12 last:mb-0 lg:mb-0 lg:flex-1 lg:px-12'>
                  {row.image && <Figure node={row.image} className='h-8 mb-4 lg:mb-8 lg:mx-auto full-height-image' />}
                  <h3 className='h4 font-body'>{row.title}</h3>
                  <BlockContent blocks={row.text} className='small' />
                </div>
              ))}
            </div>
          </section>
        </div>
      )}

      <Reviews
        className='bg-primary-background-beige py-20 lg:py-32'
        lead='Hør heller hva bekjente sier bak ryggen vår.'
        showTotals={true}
        showStars={false}
        flexLayout={true}
        title='Du trenger ikke ta vårt ord for det'
      />

      <div className='bg-primary-background-sand p-8 pt-12 lg:p-12 xl:py-24'>
        <GlobalUSP className='block lg:flex justify-between content-center max-w-screen-xl mx-auto' />
      </div>

      {page.experts && page.experts.length > 0 && (
        <section id='eksperter' className='overflow-hidden relative px-5 lg:px-8 py-20 lg:py-32'>
          <div className='radial-gradient top left'></div>

          <div className='max-w-screen-lg mx-auto'>
            <div className='mb-12'>
              <h2 className='display-h2'>Våre leger</h2>
              {page._rawExpertsText && <BlockContent className='lead max-w-screen-sm' blocks={page._rawExpertsText} />}
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-10 lg:gap-16'>
              {page.experts.map((physician, index) => (
                <div
                  className='mb-8'
                  key={physician.id}
                >
                  {physician.image && (
                    <Figure
                      node={physician.image}
                      className='mb-5'
                      aspectRatio={0.8}
                    />
                  )}

                  {physician.name && (
                    <h3 className='h5 mb-2'>{physician.name}</h3>
                  )}
                  {physician.position && (
                    <p className='font-bold text-xs uppercase'>{physician.position}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <div className='bg-primary-lighter md:flex items-center'>
        {!!pageDescriptionBlock && !!pageDescriptionBlock.image && (
          <Figure node={pageDescriptionBlock.image} className='md:w-1/2 flex-shrink-0' aspectRatio={1} />
        )}

        <div className='p-5 pb-8 md:p-16'>
          <h2 className='h3 mb-8'>En oversikt over våre tjenester</h2>
          {page._rawOurServicesText && <BlockContent className='lead mb-8' blocks={page._rawOurServicesText} />}

          {page.featuredServices.map((service, i) => (
            <LinkWrapper
              item={service}
              key={i}
              className='flex items-center relative px-2 w-1/2 mb-4 lead'
            >
              <img src={ArrowRightIcon} alt='' className='w-4 mr-6' />
              {service.text}
            </LinkWrapper>
          ))}
        </div>
      </div>

      {blog && blog.length > 0 && (
        <div className='py-20 lg:py-32 px-4 max-w-screen-lg mx-auto'>
          <h2 className='display-h2 text-center mb-8 lg:mb-12'>Lyst til å lære mer?</h2>

          <div className='flex flex-wrap md:-mx-4'>
            {blog.map(node => (
              <BlogPostPreview className='my-8 w-full md:px-4 md:w-2/4' key={node.id} compact {...node} />
            ))}
          </div>
        </div>
      )}

      {page.followUsText && (
        <div className='px-8 py-20 lg:py-32 bg-primary-background-sand text-center'>
          <div className='max-w-screen-md mx-auto'>
            <h2 className='display-h2 mb-4'>{page.followUsText.title}</h2>
            <BlockContent className='lead' blocks={page.followUsText._rawText} />
          </div>
        </div>
      )}

      { false && (<div className='px-8 py-20 bg-primary-background-beige-dark'>
        <div className='max-w-screen-lg mx-auto md:flex items-center'>
          <div className='flex-shrink-0 md:w-1/3'>
            <h2 className='h3 font-body font-bold mb-8 md:mb-0'>Meld deg på vårt nyhetsbrev</h2>
          </div>

          <KlaviyoForm className='klaviyo' listId='Sx3Hq6' />
        </div>
      </div>)}

      {page.relatedSiteInfoBox && page.relatedSiteInfoBox.title && (
        <div className='md:flex items-center' style={{backgroundColor: '#FFF6F6'}}>
          {!!page.relatedSiteInfoBox && !!page.relatedSiteInfoBox.image && (
            <Figure node={page.relatedSiteInfoBox.image} className='md:w-1/2 flex-shrink-0' aspectRatio={1} />
          )}

          <div className='p-5 pb-8 md:p-16'>
            <h2 className='font-maja font-light mb-8' style={{color: '#84700E'}}>{page.relatedSiteInfoBox.title}</h2>
            {page.relatedSiteInfoBox._rawText && <BlockContent className='mb-8' blocks={page.relatedSiteInfoBox._rawText} />}

            {page.relatedSiteInfoBox.linkText && (
              <a
                href="https://www.maja.no/"
                className='flex items-center relative px-2 w-1/2 mb-4 lead'
              >
                <img src={ArrowRightIcon} alt='' className='w-4 mr-6' />
                {page.relatedSiteInfoBox.linkText}
              </a>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    page: sanityFrontpage(_id: {regex: "/(drafts.|)frontpage/"}) {
      title
      _rawShortDescription(resolveReferences:{maxDepth:100})
      featuredServices {
        text
        url
        internalLink: _rawInternalLink(resolveReferences:{maxDepth:4})
        image {
          asset {
            id
            fluid(maxWidth: 480) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      serviceAreas {
          text
          url
      }
      image {
        asset {
          id
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      _rawSlogan
      howItWorksText {
        title
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      experts {
        id
        name
        image {
          alt
          asset {
            fluid(maxWidth: 452) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description: _rawDescription
        position
      },
      _rawExpertsText(resolveReferences:{maxDepth:100})
      _rawOurServicesText(resolveReferences:{maxDepth:100})
      followUsText {
        title
        _rawText(resolveReferences:{maxDepth:100})
      }
      relatedSiteInfoBox {
        title
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawText(resolveReferences:{maxDepth:100})
        linkText
      }
      seo {
        title
        description
      }
      articles {
        ...BlogPostPreview
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      pageDescriptionBlock {
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    categories: allSanityCategory {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`
